import React from "react";
import Layout from "../components/Layout";
import { FormSubmittedSection } from "../sections";

const FormSubmittedPage = () => {
  return (
    <Layout title="ChatIQ | Form Submitted">
      <FormSubmittedSection />
    </Layout>
  );
};

export default FormSubmittedPage;
